@import "../../common/colors";

.sso-logo {
  height: 2.5rem;
  width: auto;
}

.separator {
  align-self: center;
  background-color: use-color("base-lighter");
  height: 1px;
  width: 80%;
}
