@import "../colors";

.button {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.25s;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  // default hover effect if $state doesn't have dark variant
  &:hover {
    opacity: 0.8;
  }

  // default focus effect if $state doesn't have darker variant
  &:focus {
    opacity: 0.9;
  }
}

.button.small {
  font-size: 0.85rem;
  padding: 0.25rem 0.5rem;
}

.button.medium {
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
}

.button.large {
  font-size: 1.15rem;
  padding: 0.75rem 1rem;
}

.icon {
  margin-right: 0.5rem;
}

@each $state, $_ in $palette {
  .contained.button--#{$state} {
    background-color: use-color($state);

    @if (lightness(use-color($state)) > 40) {
      color: use-color("black");
    } @else {
      color: use-color("white");
    }

    @if (map-has-key($palette, $state + "-dark")) {
      &:hover {
        background-color: use-color($state + "-dark");
        color: use-color("white");
        opacity: 1;
      }
    }

    @if (map-has-key($palette, $state + "-darker")) {
      &:focus {
        background-color: use-color($state + "-darker");
        color: use-color("white");
      }
    }
  }

  .text.button--#{$state} {
    background: none;
    color: use-color($state);
    font-weight: bold;

    @if (map-has-key($palette, $state + "-dark")) {
      &:hover {
        background-color: use-color("light-gray");
        color: use-color($state + "-dark");
        opacity: 1;
      }
    }

    @if (map-has-key($palette, $state + "-darker")) {
      &:focus {
        color: use-color($state + "-darker");
      }
    }
  }

  .button-text--#{$state} { // TODO temporary solution
    color: use-color($state) !important;
  }
}
