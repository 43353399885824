@import "../../../common/colors";

.dropdown {
  //.dropdown
}

.menu {
  align-items: center;
  display: flex;

  .button {
    align-items: center;
    color: use-color("ink");
    display: flex;
    font-size: 1.5rem;
    justify-content: space-between;
  }
}

.permission {
  display: flex;
  justify-content: space-between;
}

.select-user {
  min-width: 30%;
}
