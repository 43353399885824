@import "../../common/colors";

ul.nav_list {
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0;
  padding: 3px 0 0;
  width: 100%;

  &:first-of-type:not(:last-of-type) {
    border-bottom: 1px solid use-color("silver");
    padding-bottom: 1rem;
  }

  .nav_item {
    align-items: center;
    background: use-color("white");
    border-radius: 4px;
    color: use-color("base-darker");
    display: flex;
    flex-flow: column nowrap;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-top: 1rem;
    padding: 0.25rem;
    position: relative;
    text-align: center;
    transition: 0.25s;

    // Items contain links, so lets remove the default link styles
    a {
      color: inherit;
      font-size: inherit;
      text-decoration: none;
    }

    &.active {
      background: use-color("base-lighter");
    }

    &:hover:not(.active) {
      background: use-rgba-color("silver", 0.5);
    }

    .nav_icon {
      font-size: 2rem;
      width: 100%;
    }

    .nav_desc {
      font-size: 0.75rem;
    }

    .nav_notifs {
      background-color: use-color("error");
      border: 3px solid use-color("white");
      border-radius: 0.8em;
      color: use-color("white");
      font-size: 12px;
      font-weight: bold;
      padding: 0.1666em 0.3333em;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.more_menu_item {
  a {
    color: inherit;
    text-decoration: none;
  }

  .more_menu_icon {
    color: use-color("base-darker");
    margin-right: 1rem;
  }
}

@each $state, $_ in $palette {
  ul.nav_list li.nav_item .nav_notifs.nav_notifs-bg--#{$state} {
    background-color: use-color($state);

    @if (lightness(use-color($state)) > 40) {
      color: use-color("black");
    } @else {
      color: use-color("white");
    }
  }
}
