@import "../../common/colors";

$border: 1px solid use-color("silver");

.card {
  background-color: use-color("white");
  border: $border;
  font-size: 16px;
  height: 100%;
  overflow: clip;
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: left;
  transition: 0.25s;

  &.clickable {
    cursor: pointer;
  }

  &.clickable:hover {
    background-color: use-color("light-gray");
  }

  &.clickable.selected:hover,
  &.clickable:active {
    background-color: use-color("primary-lightest");
  }

  &.selected {
    background-color: use-color("primary-lighter");
  }
}

.image-wrapper {
  align-self: flex-start;
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  justify-content: center;
  position: relative;

  &.image-sm {
    height: 35px;
    width: auto;
  }

  &.image-md {
    height: 60px;
    width: auto;
  }

  &.image-lg {
    height: 100px;
    width: auto;
  }

  > * {
    display: block;
    height: 100%;
    width: auto;
  }
}

.body {
  align-content: stretch;
  color: use-color("ink");
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  gap: 0.5rem;
  justify-content: center;

  .subtitle {
    color: use-color("base");
    flex-grow: 1;
    font-size: 0.75em;
  }
}

.header {
  padding-left: 1rem;
  padding-right: 1rem;
}

.content {
  flex-grow: 1;
  padding-left: 1rem;
  padding-right: 1rem;
}

.footer {
  border-top: 1px solid use-color("silver");
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
}

.card-list {
  border: $border;
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    align-items: center;
    background-color: use-color("white");
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
  }

  > li > .card {
    border: 0;
    border-bottom: $border;
    width: 100%;
  }

  > li:last-of-type> .card {
    margin-bottom: -1;
  }
}
