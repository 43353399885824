@import "../colors";

.input-container {
  background-color: use-color("white");
  border: 1px solid use-color("silver");
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  padding: 0.5rem;

  &.focus {
    border: 1px solid use-color("mid-blue");
    box-shadow: 0 0 0 1px use-color("mid-blue");
  }

  &.error {
    background-color: use-color("error-lightest");
    border: 1px solid use-color("error");
  }

  &.success {
    background-color: use-color("success-lightest");
    border: 1px solid use-color("success");
  }

  &.hidden {
    display: none;
  }
}

.input {
  border: 0;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.label {
  border: 1px solid transparent;
  margin-right: 0.5rem;

  &.error {
    border: 1px solid use-color("error");
  }

  &.success {
    border: 1px solid use-color("success");
  }
}

@each $state, $_ in $palette {
  .input--#{$state} {
    background-color: use-color($state);
    @if (lightness(use-color($state)) > 33) {
      color: use-color("black");
    } @else {
      color: use-color("white");
    }
  }
}
