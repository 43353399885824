.label-value-table {
  border-spacing: 0;
  width: 100%;
}

.label-value-table td {
  padding: 0;
}

.label-value-table th {
  font-weight: bold;
  max-width: 12rem;
  padding-right: 2em;
  text-align: right;
  vertical-align: top;
}

.label-value-table tr:not(:last-child) td {
  padding-bottom: 0.5rem;
}
