.dropdown {
  // these prefixed global classnames are inserted by react-select

  // uses the a11-compatible visually hidden props as per
  // https://www.a11yproject.com/posts/how-to-hide-content/
  // this preserves element selectability
  :global(.react-select__value-container) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 0;
  }

  :global(.react-select__indicator-separator) {
    display: none;
  }

  :global(.react-select__menu) {
    width: fit-content;
  }

  :global(.react-select__control) {
    border: 0;
    border-radius: 0;

    &:hover {
      background-color: #e0e0e0;
      border-radius: 4px;
    }
  }

  :global(.react-select__option) {
    cursor: pointer;
  }
}
