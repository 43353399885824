@import "../../../common/colors";

.narrative_item_outer {
  border-bottom: 1px solid #ccc;
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;

  &.heading {
    background: none;
    border: 0;
    border-radius: 4px;
    padding: 0 0.5rem;
  }

  &.inactive {
    background: transparent;
  }

  &:hover:not(.dropdown_wrapper) {
    background: #eee;
    transition: background 0.15s ease-in;
  }

  &.active:not(.heading) {
    background: #cdecff;
  }
}

.narrative_list_loading_outer {
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 100%;
}

.narrative_list_loading_inner {
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.list_footer {
  color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  padding: 1rem;
  text-align: center;

  &.link_action {
    color: rgb(53, 126, 221);
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      color: rgba(53, 126, 221, 0.7);
      transition: color 0.15s ease-in;
    }
  }
}

.narrative_item {
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
}

.narrative_item_details {
  color: #666;
  font-size: 0.8rem;
}

.narrative_item_inner {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.narrative_item_text {
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.narrative_item_text:not(:last-child) {
  margin-bottom: 0.5rem;
}

.narrative_item_outer.heading .narrative_item_title {
  font-size: 1.5rem;
}

.dropdown_wrapper {
  align-items: center;
  display: flex;
  justify-content: right;
}

.narrative_list_loading_inner {
  padding-bottom: 4rem;
  padding-top: 4rem;
}

.narrative_list_loading_outer {
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  width: 100%;
}

.title {
  align-items: center;
  display: flex;
  font-size: 2rem;
  margin-right: 0.5rem;
  width: 100%;

  a {
    justify-content: space-between;
    width: 100%;
  }

  .icon {
    font-size: 1.5rem;
    margin-left: 0.5rem;
    stroke: #fff;
    stroke-width: 15;
  }

  .narrative_item_details {
    display: none;
  }

  .narrative_item_inner {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  .narrative_item_text {
    align-items: center;
    font-size: 1.25rem; /* too small: 1 < 1.25 < 1.5: too big */
    width: 100%;
  }
}

.version_dropdown {
  a {
    height: 100%;
    width: 100%;
  }

  :global(.react-select__control) {
    background: rgba(0, 0, 0, 0) !important;
    min-height: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  :global(.react-select__dropdown-indicator) {
    background: rgba(0, 0, 0, 0) !important;
    color: use-color("neutral");
    cursor: pointer;

    &:hover {
      color: #777;
    }
  }

  :global(.react-select__group) {
    padding: 0;
  }

  :global(.react-select__indicator) {
    padding: 0;
  }

  :global(.react-select__indicator-separator) {
    display: block;
    height: 0;
    opacity: 0;
  }

  :global(.react-select__option) {
    color: rgb(0, 0, 0);
    display: inline-block;
    margin-right: 0.5em;
    min-height: 0;
    text-align: center;
  }

  :global(.react-select__value-container) {
    display: block;
    height: 0;
    opacity: 0;
  }
}
