@import "../../common/colors";

pre {
  margin: 0;
}

.navigator {
  min-height: 100%;
  padding: 1rem;
}

.navigator a {
  text-decoration: none;
}

.metadata {
  .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0.25rem;

    .column {
      background-color: use-color("info-lightest");
      border-radius: 0.5rem;
      margin: 0.25rem;
      padding: 0.5rem;
    }

    >.column {
      /**
       * Flex 1 expands columns to fill as much space as they can.
       * Flex-basis acts as the minimum width for the colum.
       * If exceeded, the items will wrap and expand to full width.
       */
      flex: 1;
      flex-basis: 14rem;
    }
  }

  .shares {
    background-color: use-color("info-lightest");
    border-radius: 0.5rem;
    margin: 0.25rem;
    padding: 0.5rem;
  }
}

.container {
  display: flex;
  justify-content: space-between;
}

.details {
  flex-grow: 3;
}

.header nav {
  margin-bottom: 1rem;
  position: relative;
}

.narrative-new-button {
  position: absolute;
  right: 0;
  top: 0;
}

.list {
  background-color: use-color("white");
  border-bottom: 1px solid use-color("silver");
  border-left: 1px solid use-color("silver");
  border-top: 1px solid use-color("silver");
  flex-grow: 2;
  width: 600px;
}

.more {
  //.more
}

.preview ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.search {
  display: flex;
  margin-bottom: 1rem;

  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
}

ul.shared,
.shared li {
  display: inline;
  padding-inline: 0;
}

.shared li::after {
  content: ", ";
}

.final.shared li:last-child::after {
  content: ".";
}

.shared-toggle,
.shared-toggle ~ ul.more,
.shared-toggle ~ label.less,
.shared-toggle:checked ~ label.more {
  display: none;
}

.shared-toggle ~ label.more,
.shared-toggle:checked ~ label.less {
  display: block;
}

.shared-toggle:checked ~ ul.more {
  display: inline;
}

.shared-toggle ~ label.less,
.shared-toggle ~ label.more {
  color: #00f;
  cursor: pointer;
}

.sort {
  width: 30ch;
}

.view {
  background-color: use-color("white");
  border: 1px solid use-color("silver");
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;

  .control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .tabs-container {
    //.tabs-container
    border-bottom: 1px solid use_color("silver");
    border-top: 1px solid use-color("silver");
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .tabs {
    border-bottom: 0;
    display: flex;
    font-weight: bold;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 50%;
  }

  pre {
    border: 1px dashed #000;
  }

  :global(.react-select__control) {
    //.react-select__control
  }

  :global(.react-select__indicator-separator) {
    display: block;
    height: 0;
    opacity: 0;
  }

  :global(.react-select__value-container) {
    display: block;
    height: 0;
    opacity: 0;
  }
}

.tabs {
  border-bottom: 1px solid use_color("silver");
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tabs > li:hover {
  border-bottom: 3px solid use_color("primary");
}

.tabs > li:not(:last-child) {
  margin-right: 1rem;
}

.tabs .active {
  border-bottom: 3px solid use_color("primary");
  color: use-color("primary");
}

.tabs a {
  color: inherit;
  display: inline-block;
  height: 100%;
  padding: 1rem;
}
