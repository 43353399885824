/* stylelint-disable selector-max-compound-selectors -- Need to modify sort icon in table headers */

@import "../colors";

$border: 1px solid use-color("base-lighter");
$header-height: 2em;

.table-container {
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5em;
  height: 100%;
  justify-content: flex-end;
  overflow: clip;
  width: 100%;


  .table-wrapper {
    border-bottom: $border;
    border-left: $border;
    border-right: $border;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    position: relative;
    width: inherit;
  }

  .pagination {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.pagination {
  display: block;
  text-align: right;

  * {
    display: inline-block;
    margin-left: 0.5rem;
  }

  *:first-child {
    margin-left: 0;
  }

  [disabled] {
    color: #000;
  }

  .selected {
    background-color: use-color("mid-green-light");
    color: #000;
    opacity: 1;
  }
}

@keyframes table-loading {
  from {background-color: use-color("base-light");}
  to {background-color: use-color("base");}
}

.table-wrapper .loader {
  align-items: center;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: table-loading;
  animation-timing-function: linear;
  bottom: 0;
  color: use-color("white");
  justify-content: center;
  left: 0;
  opacity: 0.5;
  padding: 1rem;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}

.table-wrapper table {
  border-collapse: separate;
  border-spacing: 0;
  height: 100%;
  position: relative;
  width: 100%;

  thead {
    position: sticky;
    top: 0;
  }

  tfoot {
    bottom: 0;
    position: sticky;
  }

  thead th {
    background-color: use-color("light-gray");
    color: use-color("base-darker");
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: bold;
    height: $header-height;
    text-align: left;
    text-transform: uppercase;
    transition: 0.25s;
    white-space: nowrap;
  }

  thead th:hover {
    color: use-color("base");
  }

  thead th > span:first-child {
    margin-right: 0.25rem;
  }

  thead th > span:first-child svg {
    color: use-color("base-light");
  }

  tfoot th {
    background-color: use-color("white");
    color: use-color("primary-dark");
    font-style: italic;
    height: $header-height;
    white-space: nowrap;
  }

  th,
  td {
    border-top: $border;
    max-width: 20em;
    overflow: clip;
    padding: 0.75rem 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td:not(:first-of-type),
  th:not(:first-of-type) {
    border-left: $border;
  }

  tbody tr:hover {
    background-color: use-color("primary-lightest");
  }
}

.sort-icon {
  color: use-rgba-color("primary-light", 0.5);

  &::after {
    content: " ";
    display: inline;
  }

  &--active {
    color: use-color("primary-lighter");;
  }
}
