.loaded_icon_outer {
  align-items: center;
  border-radius: 5px;
  display: flex;
  height: 2.5em;
  text-align: center;
  width: 2.5em;
}

.loaded_icon_inner {
  display: block;
  margin: 0 auto;
}
