@import "../../common/colors";

.topbar {
  align-items: center;
  border-bottom: 1px solid;
  border-bottom-color: use-color("silver");
  display: flex;
  height: 100%;
  justify-content: stretch;
  padding: 0 1rem;
  position: relative;
  z-index: 100;

  .topbar_item {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: auto;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;

    &.stretch {
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;
    }

    img {
      height: 40px;
    }
  }
}

.hamburger_menu {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  justify-content: center;
  width: 53px;

  .hamburger_menu_icon {
    color: use_color("mid-blue");
    font-size: 24px;
  }
}

.page_title {
  border-left: 1px solid;
  border-left-color: use-color("silver");
  color: use-color("black");
  font-size: 1.5rem;
  margin-left: 1rem;
  overflow: hidden;
  padding-left: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.environment {
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.75rem;
  font-weight: bold;
  justify-content: center;
  padding: 0 1rem;

  >svg {
    color: use-color("mid-blue");
    font-size: 1.5rem;
  }
}

.login_prompt,
.login_prompt:visited {
  color: use-color("primary");
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  text-decoration: none;
  width: 77px;

  svg {
    font-size: 24px;
  }
}

.login_menu_container {
  display: block;

  .login_menu_button {
    align-items: center;
    color: use_color("black");
    display: flex;
    gap: 5px;
    justify-content: left;
  }

  .name_item {
    text-align: center;
    width: 100%;
  }
}

.login_menu {
  a {
    color: inherit;
    text-decoration: none;
  }
  
  .login_menu_username {
    color: use-color("base");
    font-style: italic;
    margin: 0.5rem 0;
    text-align: center;
  }
  
  .login_menu_icon {
    color: use_color("base-darker");
    margin-right: 1rem;
    width: 18px;
  }
}

.narrative_link {
  margin-right: 0.5rem;
}
