.narratives {
  border: 1px dashed #000;
}

.narratives * {
  border: 1px dashed #000;
}

.profile {
  border: 1px dashed #000;
}

.profile * {
  border: 1px dashed #000;
}

.profile-narratives {
  border: 1px dashed #000;
}

.profile-nav {
  border: 1px dashed #000;
}
