/* stylelint-disable selector-class-pattern */

@import "../../common/colors";

.signup {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.signup-panel {
  padding: 1rem;
  text-align: center;
}

.sso-logo {
  height: 2.5rem;
  width: auto;
}

.separator {
  align-self: center;
  background-color: use-color("base-lighter");
  height: 1px;
  width: 80%;
}

.collapsible-message {
  &:global(.MuiAccordion-root) {
    background: none;
    box-shadow: none;
    color: inherit;
    min-height: 0;
  }

  :global(.MuiAccordionSummary-root) {
    flex-direction: row-reverse;
    min-height: 0;
    padding-left: 0.25rem;
    padding-right: 0;
  }

  :global(.MuiAccordionSummary-content) {
    margin-bottom: 0.25rem;
    margin-left: 0.5rem;
    margin-top: 0.25rem;
  }

  :global(.MuiAccordionSummary-expandIconWrapper.Mui-expanded) {
    transform: rotate(90deg);
  }

  :global(.MuiAccordionDetails-root) {
    padding-bottom: 0;
  }
}

.account-information-panel,
.use-policies-panel {
  padding: 1rem;

  :global(.MuiFormLabel-root) {
    color: #000;
  }
}

.policy-panel {
  background-color: use-color("base-lightest") !important;
  max-height: 500px;
  overflow: auto;
  padding: 1rem;

  blockquote {
    border-left: 4px solid use-color("base");
    padding-left: 1rem;
  }
}
