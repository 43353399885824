@import "../colors";

.sidebar {
  background-color: #fff;
  border-left: 1px solid use-color("silver");
  border-right: 1px solid use-color("silver");
  display: inline-block;
  flex-shrink: 0;
  height: 100%;
  overflow-y: auto;
  width: 215px;
}

.sidebar .header {
  border-bottom: 1px solid use-color("silver");
  padding: 0.5rem;
}

.sidebar .header img {
  max-height: 60px;
  max-width: 120px;
}

.sidebar ul {
  list-style: none;
  margin: 0;
  padding: 0.5rem;
}

.sidebar li.sidebar-item {
  border-radius: 4px;
  transition: 0.25s;
}

.sidebar li.sidebar-item > .sidebar-item-inner {
  border-radius: 4px;
  display: block;
  padding: 0.5rem;
  transition: 0.25s;
}

.sidebar li.sidebar-item:not(.section-label):hover > .sidebar-item-inner {
  background-color: use-color("primary-lightest");
}

.sidebar li.sidebar-item.selected > .sidebar-item-inner {
  background-color: use-color("mid-green-light");
  color: use-color("black");
  font-weight: 500;

  &:hover {
    background-color: use-color("mid-green-lighter");
  }
}

.sidebar li.section-label {
  color: use-color("base");
  font-size: 0.85rem;
  font-weight: bold;
  margin-top: 1rem;
  text-transform: uppercase;
}

.sidebar li .item-icon {
  display: inline-block;
  margin-right: 0.5rem;
  opacity: 0.7;
  width: 1rem;
}

.sidebar li a {
  color: inherit;
  text-decoration: none;
}
