@import "../colors";

.react-select {
  display: inline-block;

  // these prefixed global classnames are inserted by react-select
  :global(.react-select__option) {
    padding: 1.5px 20px;
    white-space: nowrap;
  }

  &--right {
    :global(.react-select__menu) {
      left: auto;
      right: 0;
    }
  }

  .option_content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 26px;

    .option_content_icon {
      color: use_color("mid-blue");
      display: inline-block;
      font-size: 21px;
      margin-right: 8px;
      text-align: center;
      width: 1em;
    }
  }

}
