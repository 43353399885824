@import "../../../common/colors";

$label-len: 20em;

/*
Plotly adds an body > div.dragcover element which breaks HeatMap tooltips.
See the mouseout handler in the <HeatMap /> component.
*/
:global(div.dragcover) {
  pointer-events: none;
}

:root {
  --dynamic: #00f;
}

.blank-origin {
  flex-grow: 0;
  flex-shrink: 0;
  height: 1em;
  width: 100%;
}

.heatmap-wrapper {
  position: relative;
  width: 100%;

  .heatmap {
    height: calc(100vh - 250px - 1rem);
    width: 100%;
  }

  .heatmap-hovercell {
    border: solid use-color("info") 2px;
    box-sizing: border-box;
    pointer-events: none;
    position: absolute;
  }
}

.label-wrapper {
  align-items: center;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;

  .label-indicator {
    background-color: transparent;
    display: block;
    @each $color, $_ in $palette {
      &--#{$color} {
        background-color: use-color($color);
      }
    }
  }

  .label {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    overflow: hidden;
    padding: 0.4em;
    white-space: nowrap;
  }

  .label.row {
    background-color: var(--dynamic);
    border: 1px solid #000;
  }

}

.layout {
  display: flex;
  flex-flow: row nowrap;
  font-size: 10px;
  height: calc(100vh - 250px - 1rem);
  width: 100%;
}

.layout-left {
  display: flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  overflow: hidden;
  width: $label-len;
}

.layout-right {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  overflow: hidden;
}

.tooltip {
  background: use-color("info-darker");
  border-radius: 4px;
  color: use-color("white");
  font-size: 0.667rem;
  font-weight: bold;
  max-width: 250px;
  padding: 4px 8px;
  position: fixed;
  z-index: 1;
}

.tooltip a {
  color: use-color("accent-cool-light");
}

.tooltip a:visited {
  color: use-color("accent-cool");
}

.tooltip th {
  min-width: 4rem;
  text-align: left;
  vertical-align: top;
  word-break: normal;
  word-wrap: break-word;
}

.tooltip td {
  text-align: left;
  word-break: break-all;
}

.trait-names {
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  justify-content: space-around;
  overflow: hidden;
  width: 100%;

  .label.column {
    background-color: #f0f;
    border: 1px solid #000;
    transform: rotate(90deg);
  }

  .label-wrapper {
    flex-direction: column-reverse;
  }

  .label-indicator {
    height: 1em;
    width: 100%;
  }
}

.row-names {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: space-around;
  overflow: hidden;

  .label {
    flex-basis: 0;
    line-height: 0px;
    text-align: right;
  }

  .label-wrapper {
    flex-direction: row-reverse;
  }

  .label-indicator {
    height: 100%;
    width: 1em;
  }
}
