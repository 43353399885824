/* modules/colors */

// KBase colors (generated from the KBase logo)
$kbase-palette: (

  // microbe orange
  //  Hex: #F78E1E HSL: 31 93% 54%
  "orange": rgb(247, 142, 30),

  // golden yellow
  // Hex: #FFD200 HSL: 49 100% 50%
  "yellow": rgb(255, 210, 0),

  // grass green
  // Hex: #5E9732 HSL: 94 50% 39%
  "green": rgb(94, 151, 50),

  // spring green
  // Hex: #C1CD23 HSL: 64 71% 47%
  "grellow": rgb(193, 205, 35),

  // freshwater_blue
  // Hex: #037AC0 HSL: 202 97% 38%
  "blue": rgb(3, 122, 192),

  // Hex: #72CCD2 HSL: 184 52% 64%
  "ocean_blue": rgb(114, 204, 210),

  // cyanobacteria_teal
  // Hex: #009688 HSL: 174 100% 29%
  "teal": rgb(0, 150, 136),

  // lupine purple
  // Hex: #66489D HSL: 261 37% 45%
  "purple": rgb(102, 72, 157),

  // Hex: #C7DBEE HSL: 209 53% 86%
  "frost_blue": rgb(199, 219, 238),

  // rainier_cherry_red
  // Hex: #D2232A HSL: 358 71% 48%
  "red": rgb(209, 35, 41),

  // graphite grey
  // Hex: #9D9389 HSL: 30, 9%, 58%
  "grey": rgb(157, 147, 137),
  "light-gray": rgb(238, 238, 238),

  "white": rgb(255, 255, 255),
  "black": rgb(0, 0, 0),
  "ink": rgb(23, 20, 18),
  "neutral": rgb(106, 97, 88),
  "silver": rgb(192, 192, 192),

  "base-lightest": rgb(242, 239, 235),
  "base-lighter": rgb(222, 213, 203),
  "base-light": rgb(157, 147, 137),
  "base": rgb(106, 97, 88),
  "base-dark": rgb(84, 76, 69),
  "base-darker": rgb(62, 56, 50),

  "primary-lightest": rgb(223, 238, 246),
  "primary-lighter": rgb(204, 229, 243),
  "primary-light": rgb(102, 177, 219),
  "primary": rgb(2, 109, 170),
  "primary-vivid": rgb(3, 81, 125),
  "primary-dark": rgb(2, 62, 96),
  "primary-darker": rgb(2, 41, 64),

  "secondary-lightest": rgb(229, 244, 241),
  "secondary-lighter": rgb(204, 234, 231),
  "secondary-light": rgb(153, 213, 207),
  "secondary": rgb(0, 150, 136),
  "secondary-vivid": rgb(102, 192, 183),
  "secondary-dark": rgb(17, 133, 119),
  "secondary-darker": rgb(6, 86, 77),

  "accent-cool-lightest": rgb(204, 234, 236),
  "accent-cool-lighter": rgb(170, 224, 228),
  "accent-cool-light": rgb(142, 214, 219),
  "accent-cool": rgb(71, 193, 201),
  "accent-cool-dark": rgb(39, 129, 135),
  "accent-cool-darker": rgb(10, 83, 88),

  "accent-warm-lightest": rgb(253, 202, 146),
  "accent-warm-lighter": rgb(255, 176, 92),
  "accent-warm-light": rgb(255, 161, 62),
  "accent-warm": rgb(247, 142, 30),
  "accent-warm-dark": rgb(192, 87, 25),
  "accent-warm-darker": rgb(136, 52, 4),

  "success-lightest": rgb(193, 228, 224),
  "success-lighter": rgb(153, 213, 207),
  "success-light": rgb(51, 171, 160),
  "success": rgb(17, 133, 119),
  "success-dark": rgb(10, 98, 88),
  "success-darker": rgb(0, 66, 59),

  "info-lightest": rgb(240, 244, 251),
  "info-lighter": rgb(231, 239, 248),
  "info-light": rgb(211, 226, 242),
  "info": rgb(161, 185, 207),
  "info-dark": rgb(101, 121, 140),
  "info-darker": rgb(64, 79, 93),

  "warning-lightest": rgb(255, 239, 172),
  "warning-lighter": rgb(255, 231, 118),
  "warning-light": rgb(253, 221, 73),
  "warning": rgb(255, 210, 0),
  "warning-dark": rgb(185, 153, 2),
  "warning-darker": rgb(143, 119, 0),

  "error-lightest": rgb(249, 218, 219),
  "error-lighter": rgb(246, 211, 212),
  "error-light": rgb(237, 167, 170),
  "error": rgb(210, 35, 42),
  "error-dark": rgb(182, 21, 28),
  "error-darker": rgb(124, 14, 18),

  "disabled-lightest": rgb(247, 244, 242),
  "disabled-light": rgb(242, 239, 235),
  "disabled": rgb(222, 213, 203),
  "disabled-dark": rgb(157, 147, 137),

  // other colors that pop up
  "mid-green": rgb(75, 184, 86),
  "mid-green-light": rgb(113, 198, 121),
  "mid-green-lighter": rgb(151, 199, 157),

  "mid-blue": rgb(33, 150, 243),
);

$icons: (

  "icon-app": map-get($kbase-palette, "purple"),
  "icon-generic": map-get($kbase-palette, "silver"),
  "icon-type": map-get($kbase-palette, "black"),
);

$kbase-palette: map-merge($kbase-palette, $icons);

// Job status colors
$job-status-colors: (
  "created": map-get($kbase-palette, "info"),
  "estimating": map-get($kbase-palette, "info"),
  "queued": map-get($kbase-palette, "info"),
  "running": map-get($kbase-palette, "info"),
  "completed": map-get($kbase-palette, "success-dark"),
  "terminated": map-get($kbase-palette, "warning-dark"),

  "error": map-get($kbase-palette, "error"),
  "does_not_exist": map-get($kbase-palette, "error"),
);

$palette: map-merge($kbase-palette, $job_status_colors);

// use a color from the palettes above
@function use-color($key) {
  @if map-has-key($kbase-palette, $key) {
    @return map-get($kbase-palette, $key);
  }

  @if not map-has-key($palette, $key) {
    @warn "Key `#{$key}` not found in $palette map.";
  }

  @return map-get($palette, $key);
}

// use a color from the palettes above with the specified opacity
@function use-rgba-color($key, $opacity) {
  @return rgba(use-color($key), $opacity);
}
